<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="false"
    cancelLabel="zamknij"
    :bgClose="true"
    :escCancel="true"
  >
    <template #header>
      Odzyskowanie tabeli z kopii
    </template>
    <template #default>
      <h5 class="text-lg font-semibold">Informacja na temat kopii tabeli prowizji do księgowania</h5>
      <p class="my-1">
        Podczas procesu wczytywania pliku csv, zostaje automatycznie wykonana kopia stanu prowizji niezaksięgowanych. Oznacza to, że w razie wczytania błędnego pliku
        mamy możliwość wycofania tego kroku. Podczas każdego wczytywwania powstaje nowa kopia, która zastępuje poprzednią. Data i czas ostatnio wykonanej kopii
        znajduje się po prawej stronie przycisku "Odzyskaj z kopii".
      </p>
      <p class="text-red-700 font-semibold mt-2">
        Uwaga!. Odzyskanie z kopii nie cofa prowizji ZAKSIĘGOWANYCH, jedynie przywraca tabelę prowizji do zaksięgowania z kopii.
      </p>
      <p>
        Jeśli po wczytaniu błędnego pliku zostały zaksięgowane prowizje, przed odzyskaniem z kopii należy usunąć je ręcznie w karcie polisy, lub
        w przypadku większej ilości do usunięcia można skontaktowac się z administratorem aplikacji celem oczyszczenia bazy danych przez pracowników Helpdesk.
      </p>

      <h4 class="text-xl font-semibold text-red-700 text-center">Czynność odtworzenia z kopii jest nieodwracalna!</h4>
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: 'ProvisionSettlementRestoreHelp',
};
</script>
