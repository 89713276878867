<template>
  <div class="pb-3 border-b dark:border-gray-600 sm:border-0">

    <ProvisionSettlrmrntActionsInfo v-if="actionsInfo" @close="actionsInfo = false" :color="color" />

    <span class="font-semibold" v-html="description"></span>

    <div class="flex flex-wrap gap-x-10 gap-y-1">
      <div class="flex flex-wrap gap-x-1">
        <div
          class="flex border rounded-sm divide-x bg-opacity-20 dark:bg-opacity-20"
          :class="{
            'border-green-500 divide-green-500 bg-green-500 dark:border-green-900 dark:divide-green-900 dark:bg-green-900': color === 'green',
            'border-red-300 divide-red-300 bg-red-300 dark:border-red-900 dark:divide-red-900 dark:bg-red-900': color === 'red',
            'border-blue-500 divide-blue-500 bg-blue-500 dark:border-blue-900 dark:divide-blue-900 dark:bg-blue-900': color === 'blue',
            'border-yellow-700 divide-yellow-700 bg-yellow-700 dark:border-yellow-900 dark:divide-yellow-900 dark:bg-yellow-900': color === 'brown',
            'border-yellow-300 divide-yellow-300 bg-yellow-300 dark:border-yellow-400 dark:divide-yellow-400 dark:bg-yellow-400': color === 'yellow',
            'border-red-600 divide-red-600 bg-red-600 dark:border-red-600 dark:divide-red-600 dark:bg-red-600': color === 'blocked',
          }"
        >
          <div class="w-32 px-2 text-right">{{ provisions.length }} poz.</div>
          <div class="w-32 px-2 text-right">{{ provisionsSum | moneyPL }}</div>
        </div>
        <button @click="showTable = !showTable" class="tw--btn">
          <FontAwesomeIcon :icon="showTable ? ['far', 'eye-slash'] : ['far', 'eye']" />
          {{ showTable  ? 'ukryj' : 'pokaż' }} pozycje
        </button>
        <button @click="exportToCsv" class="tw--btn" title="ekspotuj tabelę do pliku csv">
          eksport <FontAwesomeIcon :icon="['far', 'file-excel']" />
        </button>
      </div>
      <div v-if="actions" class="flex flex-wrap gap-x-1 gap-y-1">
        <button @click="$emit('storeAll', provisions, 1)" class="tw--btn">Zaksięguj <FontAwesomeIcon icon="equals" /></button>
        <button @click="$emit('storeAll', provisions, 0)" class="tw--btn tw--btn-warning">Zaksięguj <FontAwesomeIcon icon="not-equal" /></button>
        <button @click="actionsInfo = true" class="tw--btn-icon" title="kliknij: informacja na temat różnic w rodzajach księgowania">
          <FontAwesomeIcon :icon="['far', 'question-circle']" size="lg" />
        </button>
      </div>
    </div>



    <div v-if="showTable && true"
      class="flex flex-col divide-y border rounded-sm bg-opacity-20 dark:bg-opacity-20 mt-0.5 text-sm lg:text-xs 2xl:text-sm"
      :class="{
        'border-green-500 divide-green-500 bg-green-500 dark:border-green-900 dark:divide-green-900 dark:bg-green-900': color === 'green',
        'border-red-300 divide-red-300 bg-red-300 dark:border-red-900 dark:divide-red-900 dark:bg-red-900': color === 'red',
        'border-blue-500 divide-blue-500 bg-blue-500 dark:border-blue-900 dark:divide-blue-900 dark:bg-blue-900': color === 'blue',
        'border-yellow-700 divide-yellow-700 bg-yellow-700 dark:border-yellow-900 dark:divide-yellow-900 dark:bg-yellow-900': color === 'brown',
        'border-yellow-300 divide-yellow-300 bg-yellow-300 dark:border-yellow-400 dark:divide-yellow-400 dark:bg-yellow-400': color === 'yellow',
        'border-red-600 divide-red-600 bg-red-600 dark:border-red-600 dark:divide-red-600 dark:bg-red-600': color === 'blocked',
      }"
    > <!-- wrapper - tabela -->

      <!-- nagłówek tabeli - wiersz -->
      <div class="hidden lg:flex flex-nowrap text-bright py-1">
        <!-- kolumna 1 z 3 - tyllo LP -->
        <div class="lg:w-10 flex-none self-center px-2">lp</div>
        <!-- Kolumna 2 - 3 bloki: POLISA | KLIENT | DANE FINASOWE -->
        <div class="min-w-0 flex-grow flex">
          <!-- Podkolumna 1 z 3 - TU/Polisa/agent -->
          <div class="lg:w-3/12 xl:w-1/3 2xl:w-2/5 flex lg:flex-col xl:flex-row">
            <!-- TU + polisa -->
            <div class="xl:w-3/5 flex lg:flex-col 2xl:flex-row">
              <div class="lg:w-full 2xl:w-1/3 flex-none px-0.5 self-start">TU</div>
              <div class="lg:w-full 2xl:w-2/3 flex-none px-0.5 self-start">polisa</div>
            </div>
            <!-- Agent -->
            <div class="lg:w-full xl:w-2/5 flex-none px-0.5 lg:text-left self-center">agent</div>
          </div>
          <!-- Podkolumna 2 z 3 - Klient plik/klinet baza -->
          <div class="lg:w-3/12 xl:w-1/3 2xl:w-1/5 flex flex-col justify-center px-1">klient (plik/baza)</div>
          <!-- Podkolumna 3 z 3 - informacje finansowe -->
          <div class="lg:w-6/12 xl:w-1/3 2xl:w-2/5 flex pl-1">
            <div class="lg:w-20 2xl:w-36 flex-shrink-0 self-center">wczytano</div>
            <div class="flex-grow flex">
              <div class="w-1/3 text-right self-center">wpłynęło</div>
              <div class="flex-grow flex">
                <div class="w-1/2 text-right self-center">oczekiwana</div>
                <div class="w-1/2 text-right self-center">różnica</div>
              </div>
            </div>
          </div>
        </div>
        <!-- Podkolumna 3 z 3 - informacje finansowe -->
        <div class="lg:w-20 flex-none">&nbsp;</div>
      </div>

      <!-- wrapper - wiersz -->
      <div  v-for="(prov, index) in provisions" :key="index"
        class="flex flex-wrap lg:flex-nowrap py-0.5"
      >

        <!-- kolumna 1 z 3 - tyllo LP -->
        <div class="order-1 w-1/2 lg:w-10 flex-none flex items-center px-2 border-b lg:border-none border-gray-700 dark:border-gray-500 border-opacity-20 dark:border-opacity-20">{{ index + 1 }}</div>

        <!-- Kolumna 2 - 3 bloki: POLISA | KLIENT | DANE FINASOWE -->
        <div class="order-3 lg:order-2 min-w-0 flex-grow flex flex-col lg:flex-row">

          <!-- Podkolumna 1 z 3 - TU/Polisa/agent -->
          <div class="lg:w-3/12 xl:w-1/3 2xl:w-2/5 flex flex-col sm:flex-row lg:flex-col xl:flex-row">

            <!-- TU + polisa -->
            <div class="sm:w-2/3 lg:w-full xl:w-3/5 flex flex-col sm:flex-row lg:flex-col 2xl:flex-row">
              <div class="sm:w-2/5 lg:w-full 2xl:w-1/3 flex-none px-0.5 self-start sm:self-center">{{ prov.insurer_name }}</div>
              <div class="sm:w-3/5 lg:w-full 2xl:w-2/3 flex-none px-0.5 self-start sm:self-center flex flex-col" title="numer polisy">
                <router-link v-if="prov.order_id != ''"
                  :to="{name: 'Order', params: { id: prov.order_id}}"
                  class="tw--link break-words"
                  target="_blank"
                >{{ prov.policy_no }}</router-link>
                <span v-else class="break-words">{{ prov.policy_no }}</span>
                <span v-if="prov.status_id !== '10'" class="text-yellow-600">{{ prov.status_name }}</span>
              </div>
            </div> <!-- TU + polisa -->

            <!-- Agent -->
            <div class="sm:w-1/3 lg:w-full xl:w-2/5 flex-none px-0.5 sm:text-right lg:text-left self-start sm:self-center break-words" title="agent">
              <FontAwesomeIcon v-show="prov.empl_name" icon="user-tie" class="text-muted" />&nbsp;{{ prov.empl_name }}
            </div> <!-- Agent -->

          </div> <!-- Podkolumna 1 z 3 - TU/Polisa/agent -->

          <!-- Podkolumna 2 z 3 - Klient plik/klinet baza -->
          <div class="lg:w-3/12 xl:w-1/3 2xl:w-1/5 flex flex-col justify-center border-b lg:border-none border-gray-700 dark:border-gray-500 border-opacity-20 dark:border-opacity-20 px-1">
            <div class="overflow-x-hidden overflow-ellipsis whitespace-nowrap" title="klient wczytany z pliku csv">
              <FontAwesomeIcon icon="file-csv" class="text-muted" />&nbsp;
              <span class="whitespace-nowrap" :title="prov.customer_name">{{ prov.customer_name.length > 40 ? `${prov.customer_name.slice(0,40)}...` : prov.customer_name }}</span>
            </div>
            <div class="overflow-x-hidden overflow-ellipsis whitespace-nowrap" title="klient odnaleziony w bazie">
              <FontAwesomeIcon icon="database" class="text-muted" />&nbsp;
              <span class="whitespace-nowrap">{{ prov.ord_customer_name }}</span>
            </div>
          </div>

          <!-- Podkolumna 3 z 3 - informacje finansowe -->
          <div class="lg:w-6/12 xl:w-1/3 2xl:w-2/5 flex flex-col sm:flex-row pl-1">
            <div class="sm:w-1/3 lg:w-20 2xl:w-36 flex-shrink-0 self-start sm:self-center">{{ prov.date_import_h }}</div>
            <div class="sm:w-2/3 sm:flex-grow-0 lg:w-auto lg:flex-grow flex flex-col sm:flex-row">
              <div class="sm:w-1/3 text-left sm:text-right sm:self-center"><span class="text-muted lg:hidden">wpłynęło&nbsp;</span>{{ prov.income | moneyPL }}</div>
              <div class="sm:w-2/3 flex flex-col lg:flex-row">
                <div class="w-full lg:w-1/2 text-left sm:text-right sm:self-center"><span class="text-muted lg:hidden">oczekiwana&nbsp;</span>{{ prov.oczekiwana | moneyPL }}</div>
                <div class="w-full lg:w-1/2 text-left sm:text-right sm:self-center"><span class="text-muted lg:hidden">różnica&nbsp;</span>{{ prov.roznica_val | moneyPL }}</div>
              </div>
            </div>
          </div>

        </div> <!-- Kolumna 2 - 3 bloki: POLISA | KLIENT | DANE FINASOWE -->

        <!-- Kolumna 3 z 3 - Przyciski akcji -->
        <div class="order-2 lg:order-3 w-1/2 lg:w-20 flex-none flex items-center justify-end px-2 border-b lg:border-none border-gray-700 dark:border-gray-500 border-opacity-20 dark:border-opacity-20">
          <button v-if="actions" @click="$emit('store', prov, 1)" class="tw--btn-icon" title="Księguj pozycję proporcjonalenie"><FontAwesomeIcon icon="equals" /></button>
          <button v-if="actions" @click="$emit('store', prov, 0)" class="tw--btn-icon tw--btn-icon-warning" title="Księguj pozycję nieproporcjonalenie"><FontAwesomeIcon icon="not-equal" /></button>
          <button @click="$emit('delete', prov.id)" class="tw--btn-icon tw--btn-icon-danger" title="Usuń pozycję"><FontAwesomeIcon :icon="['far', 'trash-alt']" /></button>
        </div>

      </div> <!-- wrapper - wiersz -->


    </div> <!-- wrapper - tabela -->

  </div>
</template>

<script>
import ProvisionSettlrmrntActionsInfo from './ProvisionSettlementActionsInfo.vue';

export default {
  name: 'ProvisionSettlementTable',
  components: {ProvisionSettlrmrntActionsInfo},
  props: {
    description: {
      type: String,
      required: true,
    },
    provisions: {
      required: true,
    },
    provisionsSum: {
      type: [Number, String],
      required: true,
    },
    color: {
      default: 'all',
    },
    actions: {
      required: true,
    },
  },
  data() {
    return {
      showTable: false,
      actionsInfo: false,
    };
  },
  methods: {
    exportToCsv() {
      const header = 'data:text/csv;charset=utf-8,';
      // const universalBom = "\uFEFF";
      let content = "\uFEFFTU;polisa;";
      if (this.color === 'blocked') {
        content = `${content}status;`;
      }
      content = `${content}agent;klient-pik;klient-baza;wczytano;wpłynęło;oczekiwana;różnica\r\n`;
      this.provisions.forEach(row => {
        content = `${content}"${row.insurer_name}";"${row.policy_no}";`;
        if (this.color === 'blocked') {
          content = `${content}"${row.status_name}";`;
        }
        content = `${content}"${row.empl_name}";"${row.customer_name}";"${row.ord_customer_name}";${row.date_import_h};`;
        content = `${content}${Number(row.income).toFixed(2).replace('.',',')};${Number(row.oczekiwana).toFixed(2).replace('.',',')};${Number(row.roznica_val).toFixed(2).replace('.',',')}\r\n`;
      });
      const encodedContent = encodeURI(`${header}${content}`);
      const link = document.createElement('a');
      link.setAttribute('href', encodedContent);
      link.setAttribute('download', 'prowizje.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  },
};
</script>
