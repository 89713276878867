<template>
  <div class="flex flex-col gap-y-2">
    <div id="provision-settlement-header" class="flex flex-col gap-y-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div id="filters" class="flex flex-col sm:flex-row gap-x-3 gap-y-2">
        <div class="w-full">
          <div id="provision-settlement-adv-filters-1" class="flex flex-col gap-y-3">
            <div class="flex flex-wrap justify-between gap-y-3">

              <ProvisionSettlementRestoreHelp v-if="helpBackupNotification" @close="helpBackupNotification = false" />
              <ProvisionSettlementHelp v-if="helpModal" @close="helpModal = false" />

              <div class="flex flex-wrap gap-x-1 gap-y-1">
                <label>
                  <input id="csv-file" type="file" @change="fileChange($event.target.files)" accept=".csv" ref="csvFile">
                </label>

                <div class="flex gap-x-1">
                  <button @click="upload" class="tw--btn" :disabled="filesToUpload.length != 1">Wczytaj</button>
                  <button @click="resetFileForm" class="tw--btn tw--btn-secondary" :disabled="filesToUpload.length != 1">Anuluj</button>
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="flex">
                  <button @click="helpBackupNotification = !helpBackupNotification" class="tw--btn tw--btn-secondary rounded-r-none"><FontAwesomeIcon :icon="['far', 'question-circle']" /></button>
                  <button @click="restoreFromBackup" class="tw--btn rounded-none" :disabled="backupTime === ''">Odzyskaj z kopii:</button>
                </div>
                <div class="flex gap-1 items-center px-1 border border-l-0 dark:border-gray-600 rounded-sm rounded-l-none">
                  <FontAwesomeIcon :icon="['far', 'calendar-alt']" />
                  <span class="text-xs sm:text-sm">{{ backupTime !== '' ? backupTime : 'kopia niedostępna' }}</span>
                </div>
              </div>

              <div class="flex gap-x-1">
                <button @click="deleteAll" class="tw--btn tw--btn-danger" :disabled="tmpProvisions.length == 0">Usuń wszystko</button>
                <button @click="deleteAllNotLinked" class="tw--btn tw--btn-warning" :disabled="tmpProvisions.length == 0">Usuń nieodnalezione</button>
              </div>

            </div>

            <div class="flex flex-wrap items-center gap-3">

              <div class="flex flex-none">
                <input
                  class="w-16 border-blue-600 dark:border-blue-900 border-opacity-50 dark:border-opacity-50 rounded-r-none border-r-0"
                  v-model="greenTolerance"
                  type="number"
                >
                <button
                  @click="setCategory"
                  class="tw--btn border border-blue-600 dark:border-blue-900 rounded-l-none"
                  type="button"
                  :disabled="Number(greenTolerance) === activeTolerance"
                >
                  Ustaw tolerancję (zł)
                </button>
                <HelpContainer direction="right" class="flex items-center">
                  <strong>Wybierz tolerancję zł</strong><br>
                  <span>Ustaw kwotę w zł (np 7,50) do której różnica jest akceptowalna i wczytane prowizje zostaną przeniesione do tabeli "Poprawne" pomimo różnicy.</span>
                </HelpContainer>
              </div>

              <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
                <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">razem</span>
                <span class="px-3 py-1 bg-indigo-500 dark:bg-indigo-900">{{ tmpProvisions.length }} poz.</span>
                <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ tmpProvisionsSum | moneyPL }}</span>
              </div>

            </div>

          </div>
        </div>

        <div id="clear-refresh-buttons" class="flex order-first sm:order-last justify-end">
          <div class="flex gap-0.5 justify-end items-start">
            <button @click="helpModal = !helpModal" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="Pomoc dot. wczytywania prowizji">
              <FontAwesomeIcon :icon="['far', 'question-circle']" />
            </button>
            <button @click="getTmpProvisions" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
          </div>
        </div>
      </div>
      <Transition name="fade">
        <div v-if="err_duplicates.length > 0" class="px-2 py-1 rounded bg-red-300 dark:bg-red-800">
          <h1 class="font-semibold">Plik został wczytany, ale księgowanie zostało zablokowane</h1>
          <p>Znaleziono duplikaty polis, do których wpłynęły prowizje. W aplikacji numery polis nie mogą się dublować - popraw błędy aby wznowić proces księgowania prowizji.</p>
          <p>Po usunięciu duplikatu(ów) kliknij
            <button @click="getTmpProvisions" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
            aby odświeżyć dane z chmury.
          </p>
          <p>Poniżej linki do wyszukiwania duplikatów
            <span class="text-sm italic">(kliknij w pozycję, aby otworzyć duplikacy polis w nowym oknie)</span>:
            </p>
          <p v-for="(order, index) in err_duplicates" :key="index">
            <router-link :to="{ name: 'Orders', query: { search: order.policy_no}}" class="tw--link" target="_blank">{{ order.policy_no }} - duplikatów: {{ order.cnt }}</router-link>
            <!-- <router-link :to="'/sprzedaz?search='+order.policy_no">{{ order.policy_no }} - duplikatów: {{ order.cnt }}</router-link> -->
          </p>
        </div>
      </Transition>
      <Transition name="fade">
        <div v-show="errors.fileStructure != undefined" class="p3 rounded bg-yellow-200 dark:bg-yellow-700">
          <button @click="errors.fileStructure = undefined" class="tw--btn-icon"><FontAwesomeIcon icon="times" /></button>
          {{ errors.fileStructure }}
        </div>
      </Transition>
    </div>

    <div v-if="err_duplicates.length === 0" id="provision-settlement-content" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div class="flex flex-col">
        <span class="font-semibold">Poniżej znajdują się tabele prowizji do zaksięgowania lub wyjaśnienia różnic</span>
      </div>

      <hr class="dark:border-gray-600 -mx-3">

      <div class="flex flex-col gap-y-4 mt-2">

        <div v-if="greenProvisions.length === 0 && redProvisions.length === 0 && blueProvisions.length === 0 && brownProvisions.length === 0 && blockedProvisions.length === 0 && yellowProvisions.length === 0"
         class="text-lg font-semibold mb-10"
        >Brak prowizji do księgowania. Wczytaj plik prowizyjny.</div>

        <ProvisionSettlementTable v-if="greenProvisions.length > 0"
          @delete="deleteOne"
          @store="storeOne"
          @storeAll="storeProvisions"
          :provisions="greenProvisions"
          :provisionsSum="greenProvisionsSum"
          color="green"
          :actions="true"
          :description="`Wpłyneła prowizja w granicach tolerancji&nbsp;
            <span class='text-purple-500 dark:text-purple-600'>&#177;${activeTolerance.toFixed(2).replace('.',',')}zł</span>
            &nbsp;(możliwa zmiana tolerancji w menu)`"
        />

        <ProvisionSettlementTable v-if="redProvisions.length > 0"
          @delete="deleteOne"
          @store="storeOne"
          @storeAll="storeProvisions"
          :provisions="redProvisions"
          :provisionsSum="redProvisionsSum"
          color="red"
          :actions="true"
          description="Wpłynęła niższa prowizja od oczekiwanej lub prowizja ujemna (potrącenie)"
        />

        <ProvisionSettlementTable v-if="blueProvisions.length > 0"
          @delete="deleteOne"
          @store="storeOne"
          @storeAll="storeProvisions"
          :provisions="blueProvisions"
          :provisionsSum="blueProvisionsSum"
          color="blue"
          :actions="true"
          description="Wpłynęło więcej od oczekiwanej prowizji, ale nie więcej niż dwukrotność"
        />

        <ProvisionSettlementTable v-if="brownProvisions.length > 0"
          @delete="deleteOne"
          @store="storeOne"
          @storeAll="storeProvisions"
          :provisions="brownProvisions"
          :provisionsSum="brownProvisionsSum"
          color="brown"
          :actions="true"
          description="Wpłynęło dwukrotnie lub ponad dwukrotnie więcej od oczekiwanej prowizji"
        />

        <ProvisionSettlementTable v-if="blockedProvisions.length > 0"
          @delete="deleteOne"
          :provisions="blockedProvisions"
          :provisionsSum="blockedProvisionsSum"
          color="blocked"
          :actions="false"
          description="Prowizje zablokowane (status inny niż kompletna lub zakończona)"
        />

        <ProvisionSettlementTable v-if="yellowProvisions.length > 0"
          @delete="deleteOne"
          :provisions="yellowProvisions"
          :provisionsSum="yellowProvisionsSum"
          color="yellow"
          :actions="false"
          description="Prowizje nieodnalezione w aplikacji lub brak wprowadzonej składki/raty"
        />
      </div>

    </div>
  </div>
</template>

<script>
import { confirm } from '../../mixins/confirm';
import { errorHandler } from '../../mixins/errorHandler';
import ProvisionSettlementHelp from './ProvisionSettlementHelp.vue';
import ProvisionSettlementRestoreHelp from './ProvisionSettlementRestoreHelp.vue';
import ProvisionSettlementTable from './ProvisionSettlementTable.vue';
import ProvisionService from '../../services/ProvisionService';

export default {
  name: 'ProvisionSettlement',
  components: {
    ProvisionSettlementTable,
    ProvisionSettlementHelp,
    ProvisionSettlementRestoreHelp,
  },
  mixins: [confirm, errorHandler],
  data() {
    return {
      tmpProvisions: [],
      tmpProvisionsSum: 0,
      filesToUpload: [],
      errors: {},
      fileToUpload: '',
      greenTolerance: '1',
      activeTolerance: 1,
      greenProvisions: [],
      greenProvisionsSum: 0,
      redProvisions: [],
      redProvisionsSum: 0,
      blueProvisions: [],
      blueProvisionsSum: 0,
      brownProvisions: [],
      brownProvisionsSum: 0,
      yellowProvisions: [],
      yellowProvisionsSum: 0,
      blockedProvisions: [],
      blockedProvisionsSum: 0,
      backupTime: '',
      showAllTable: false,
      helpModal: false,
      helpBackupNotification: false,
      err_duplicates: [],
    };
  },
  computed: {
    currentUserType() {
      return this.$store.getters.currentUserType;
    },
  },
  created() {
    this.getTmpProvisions();
  },
  methods: {
    fileChange(fileList) {
      this.filesToUpload = fileList;
    },
    getTmpProvisions() {
      this.errors = {};
      this.err_duplicates = [];
      this.$store.dispatch('setLoadingData', true);
      ProvisionService.getTmpProvisions()
        .then(response => {
          this.tmpProvisions = response.data.tmpProvisions;
          this.setCategory();
          this.checkBackup();
        })
        .catch(error => {
          console.log(error);
          this.$store.dispatch('setLoadingData', false);
          this.errors = this.resolveError(error);
          if (error.response !== undefined) {
            this.err_duplicates = Array.isArray(error.response.data.err_duplicates) ? error.response.data.err_duplicates : [];
          }
        });
    },
    checkBackup() {
      ProvisionService.checkProvisionsTmpBackup()
        .then(response => {
          if (response.data.result !== undefined && response.data.result !== '') {
            this.backupTime = response.data.result.time;
          }
        })
        .catch(error => this.resolveError(error));
    },
    restoreFromBackup() {
      const message = `Operacja przywraca tabelę niezaksięgowanych prowizji z kopii z dnia <span class="whitespace-nowrap">${this.backupTime}</span>.<br>UWAGA! Oprecja nie cofa zaksięgowanych prowizji!
        Jeśli od momentu utworzenia ostatniej kopii (wczytania ostatniego pliku CSV) były księgowane prowizje, należy wycofać je ręcznie z kart polis.
        Czy potwierdzasz przywrócenie tabel z ostatniej kopii?`;
      this.confirm(() => this.confirmedRestoreFromBackup(), message);
    },
    confirmedRestoreFromBackup() {
      this.$store.dispatch('setLoadingData', true);
      ProvisionService.restoreProvisionTmpFromBackup()
        .then(response => {
          this.getTmpProvisions();
          this.$store.dispatch('notifications/add', { message: response.data.msg, type: 'success' });
        })
        .catch(error => {
          this.$store.dispatch('setLoadingData', false);
          this.resolveError(error);
        });
    },
    upload() {
      this.$store.dispatch('setLoadingData', true);
      const formData = new FormData();
      formData.append('prowizja', this.filesToUpload[0], this.filesToUpload[0].name);
      ProvisionService.uploadProvisionsFile(formData)
        .then(() => {
          this.getTmpProvisions();
          this.resetFileForm();
        })
        .catch(error => {
          this.errors = this.resolveError(error);
          this.resetFileForm();
          this.$store.dispatch('setLoadingData', false);
        });
    },
    setCategory() {
      this.activeTolerance = Number(this.greenTolerance);
      this.tmpProvisionsSum = 0;
      this.blockedProvisions = [];
      this.blockedProvisionsSum = 0;
      this.greenProvisions = [];
      this.greenProvisionsSum = 0;
      this.redProvisions = [];
      this.redProvisionsSum = 0;
      this.blueProvisions = [];
      this.blueProvisionsSum = 0;
      this.brownProvisions = [];
      this.brownProvisionsSum = 0;
      this.yellowProvisions = [];
      this.yellowProvisionsSum = 0;
      this.tmpProvisions.forEach((prov) => {
        this.tmpProvisionsSum += Number(prov.income);
        if (prov.order_id === '') {
          this.yellowProvisions.push(prov);
          this.yellowProvisionsSum += Number(prov.income);
        } else if (prov.status_id !== '10' && prov.status_id !== '11') {
          this.blockedProvisions.push(prov);
          this.blockedProvisionsSum += Number(prov.income);
        } else if (Math.abs(prov.roznica_val) <= this.activeTolerance && prov.roznica_val !== '') {
          this.greenProvisions.push(prov);
          this.greenProvisionsSum += Number(prov.income);
        } else if (prov.roznica_val < -this.activeTolerance) {
          this.redProvisions.push(prov);
          this.redProvisionsSum += Number(prov.income);
        } else if (prov.roznica_val > this.activeTolerance && prov.roznica_perc < 100) {
          this.blueProvisions.push(prov);
          this.blueProvisionsSum += Number(prov.income);
        } else if (prov.roznica_perc >= 100) {
          this.brownProvisions.push(prov);
          this.brownProvisionsSum += Number(prov.income);
        } else {
          this.yellowProvisions.push(prov);
          this.yellowProvisionsSum += Number(prov.income);
        }
      });
      this.$store.dispatch('setLoadingData', false);
    },
    storeProvisions(provisions, met) {
      const countText = provisions.length > 1 ? 'tabeli' : '';
      const methodText = Number(met) === 1 ? 'proporcjonalnie' : 'nieproporcjonalnie';
      this.confirm(() => this.confirmedStoreProvisions(provisions, met), `Potwierdź księgowanie wybranej ${countText} prowizji metodą: ${methodText}.<br>
        Czynności nie można cofnąć z poziomu aplikacji.`);
    },
    confirmedStoreProvisions(provisions, met) {
      console.log(provisions);
      console.log(met);
      this.$store.dispatch('setLoadingData', true);
      const post = [];
      let row = {};
      provisions.forEach((obj) => {
        row = {
          id: obj.id,
          order_id: obj.order_id,
          skladka: obj.skladka,
          ord_income: obj.ord_income,
          prov_a: obj.prov_a,
          prov_cw: obj.prov_cw,
          ost_rata: obj.ost_rata,
          income: obj.income,
          ratio: met,
        };
        post.push(row);
      });
      ProvisionService.autoStoreProvision(post)
        .then(response => {
          this.$store.dispatch('notifications/add', { message: response.data.msg, type: 'success' });
          this.getTmpProvisions();
        })
        .catch(error => {
          this.$store.dispatch('setLoadingData', false);
          this.resolveError(error);
        });
    },
    deleteAll() {
      this.confirm(() => this.confirmedDeleteAll(), 'Potwierdź usunięcie wszystkich wpisów z tablicy tymczasowych prowizji przygotowanych do księgowania. Operacja jest nieodwracalna.');
    },
    confirmedDeleteAll() {
      this.$store.dispatch('setLoadingData', true);
      ProvisionService.truncateTmpProvisions()
        .then(response => {
          this.getTmpProvisions();
          this.$store.dispatch('notifications/add', { message: response.data.msg, type: 'success' });
        })
        .catch(error => {
          this.$store.dispatch('setLoadingData', false);
          this.resolveError(error);
        });
    },
    deleteAllNotLinked() {
      this.confirm(() => this.confirmedDeleteAllNotLinked(), 'Potwierdź usunięcie wszystkich wpisów z tablicy, dla których nie odnaleziono polis w aplikacji (jasno-żółta tabela). Operacja jest nieodwracalna.');
    },
    confirmedDeleteAllNotLinked() {
      this.$store.dispatch('setLoadingData', true);
      ProvisionService.deleteTmpProvisionsNotLinked()
        .then(response => {
          this.$store.dispatch('notifications/add', { message: response.data.msg });
          this.getTmpProvisions();
        })
        .catch(error => {
          this.$store.dispatch('setLoadingData', false);
          this.resolveError(error);
        });
    },
    deleteOne(id) {
      this.confirm(() => this.confirmedDeleteOne(id), 'Potwierdź usunięcie pozycji. Operacja jest nieodwracalna.');
    },
    confirmedDeleteOne(id) {
      this.$store.dispatch('setLoadingData', true);
      ProvisionService.deleteTmpProvision(id)
        .then((response) => {
          this.$store.dispatch('notifications/add', { message: response.data.msg, type: 'success' });
          this.tmpProvisions = this.tmpProvisions.filter(obj => Number(obj.id) !== Number(id));
          this.getTmpProvisions();
        })
        .catch(error => {
          this.$store.dispatch('setLoadingData', false);
          this.resolveError(error);
        });
    },
    storeOne(provision, met) {
      const provisions = [];
      provisions.push(provision);
      this.storeProvisions(provisions, met);
    },
    resetFileForm() {
      const file = document.querySelector('#csv-file');
      file.value = '';
      this.filesToUpload = [];
    },
  },
};
</script>
