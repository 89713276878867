<template>
  <BaseModal
    @closeModal="$emit('close')"
    :bgClose="true"
    :cancel="false"
    :escCancel="true"
    @ok="$emit('close')"
  >
    <template #header>Rodzaje księgowania: <FontAwesomeIcon icon="equals" /> i <FontAwesomeIcon icon="not-equal" /></template>
    <template #default>
      <p>Jeśli prowizja z wczytanego pliku różni się od oczekiwanej przez system (nawet o kilka groszy) mamy dwie możliwości:</p>
      <ul class="list-outside list-disc ml-5 mb-3">
        <li>
          <button class="tw--btn" disabled>Zaksięguj <FontAwesomeIcon icon="equals" /></button> -
          kwota nadwyżki lub braku zostanie proporcjonalnie rozdzielona na agenta i agencję (wg. stosunku prowizji agenta do prowizji agencji)
        </li>
        <li>
          <button class="tw--btn tw--btn-warning" disabled>Zaksięguj <FontAwesomeIcon icon="not-equal" /></button> -
          kwota nadwyżki lub braku zostanie zaksięgowana po stronie agencji
        </li>
      </ul>

      <div v-if="color === 'all' || color === 'green' || color === 'blue'">
        <h1 class="font-semibold">Przykład - nadwyżka</h1>
        <div class="px-2 py-1 border dark:border-gray-600 rounded-md text-sm">
          <p>Oczekiwana kwota prowizji: <span class="text-bright">100zł</span>, wpłynęło <span class="text-bright">110zł</span> (nadwyżka: <span class="text-bright">10zł</span>)</p>
          <p>Przykładowa tabela prowizji dla produktu to: przychód <span class="text-bright">10%</span>, agent <span class="text-bright">8%</span>,
            agencja <span class="text-bright">2%</span> (czyli <span class="text-bright">80%</span> wpływu otrzymuje agent, <span class="text-bright">20%</span> agencja)</p>
          <p><button class="tw--btn" disabled>Zaksięguj <FontAwesomeIcon icon="equals" /></button> - 10zł nadwyżki zostaje rozdzielone proporcjonalnie.</p>
          <p class="ml-5">
            AGENT: <span class="text-bright">88zł</span> <span class="italic">(80zł + 8zł nadwyzki)</span><br>
            AGENCJA: <span class="text-bright">22zł</span> <span class="italic">(20zł + 2zł nadwyżki)</span>
          </p>
          <p><button class="tw--btn tw--btn-warning" disabled>Zaksięguj <FontAwesomeIcon icon="not-equal" /></button> - 10zł nadwyżki zostaje przeniesione w całości na agencję.</p>
          <p class="ml-5">
            AGENT: <span class="text-bright">80zł</span> <span class="italic">(oczekiwana)</span><br>
            AGENCJA: <span class="text-bright">30zł</span> <span class="italic">(20zł + 10zł nadwyżki)</span>
          </p>
        </div>
      </div>

      <div v-if="color === 'all' || color === 'green' || color === 'red'">
        <h1 class="font-semibold">Przykład - niedopłata</h1>
        <div class="px-2 py-1 border dark:border-gray-600 rounded-md text-sm">
          <p>Oczekiwana kwota prowizji: <span class="text-bright">100zł</span>, wpłynęło <span class="text-bright">90zł</span> (brak: <span class="text-bright">10zł</span>)</p>
          <p>Przykładowa tabela prowizji dla produktu to: przychód <span class="text-bright">10%</span>, agent <span class="text-bright">8%</span>,
            agencja <span class="text-bright">2%</span> (czyli <span class="text-bright">80%</span> wpływu otrzymuje agent, <span class="text-bright">20%</span> agencja)</p>
          <p><button class="tw--btn" disabled>Zaksięguj <FontAwesomeIcon icon="equals" /></button> - 10zł braku zostaje rozdzielone proporcjonalnie.</p>
          <p class="ml-5">
            AGENT: <span class="text-bright">72zł</span> <span class="italic">(80zł - 8zł)</span><br>
            AGENCJA: <span class="text-bright">18zł</span> <span class="italic">(20zł - 2zł)</span>
          </p>
          <p><button class="tw--btn tw--btn-warning" disabled>Zaksięguj <FontAwesomeIcon icon="not-equal" /></button> - 10zł braku zostaje przeniesione w całości na agencję.</p>
          <p class="ml-5">
            AGENT: <span class="text-bright">80zł</span> <span class="italic">(oczekiwana)</span><br>
            AGENCJA: <span class="text-bright">10zł</span> <span class="italic">(20zł - 10zł)</span>
          </p>
        </div>
      </div>

      <div v-if="color === 'all' || color === 'red'">
        <h1 class="font-semibold">Przykład - potrącenie (np sprzedaż auta)</h1>
        <div class="px-2 py-1 border dark:border-gray-600 rounded-md text-sm">
          <p>Klient sprzedał pojazd, wpłynęło potrącenie <span class="text-bright">-10zł</span></p>
          <p>Przykładowa tabela prowizji dla produktu to: przychód <span class="text-bright">10%</span>, agent <span class="text-bright">8%</span>,
            agencja <span class="text-bright">2%</span> (czyli <span class="text-bright">80%</span> wpływu otrzymuje agent, <span class="text-bright">20%</span> agencja)</p>
          <p><button class="tw--btn" disabled>Zaksięguj <FontAwesomeIcon icon="equals" /></button> - 10zł potrącenia zostaje rozdzielone proporcjonalnie.</p>
          <p class="ml-5">
            AGENT: <span class="text-bright">-8zł</span><br>
            AGENCJA: <span class="text-bright">-2zł</span>
          </p>
          <p><button class="tw--btn tw--btn-warning" disabled>Zaksięguj <FontAwesomeIcon icon="not-equal" /></button> - 100zł potrącenia przeniesione w całości na agencję.</p>
          <p class="ml-5">
            AGENT: <span class="text-bright">0zł</span><br>
            AGENCJA: <span class="text-bright">-10zł</span>
          </p>
        </div>
      </div>

      <div v-if="color === 'all' || color === 'brown'">
        <h1 class="font-semibold">Przykład - b.duża nadpłata (podwójna lub wyższa)</h1>
        <div class="px-2 py-1 border dark:border-gray-600 rounded-md text-sm">
          <p>Oczekiwana kwota prowizji: <span class="text-bright">100zł</span>, klient wpłacił obie raty za jednym razem, wpłynęło <span class="text-bright">200zł</span> (nadwyżka: <span class="text-bright">100zł</span>)</p>
          <p>Przykładowa tabela prowizji dla produktu to: przychód <span class="text-bright">10%</span>, agent <span class="text-bright">8%</span>,
            agencja <span class="text-bright">2%</span> (czyli <span class="text-bright">80%</span> wpływu otrzymuje agent, <span class="text-bright">20%</span> agencja)</p>
          <p><button class="tw--btn" disabled>Zaksięguj <FontAwesomeIcon icon="equals" /></button> - 100zł nadwyżki zostaje rozdzielone proporcjonalnie.</p>
          <p class="ml-5">
            AGENT: <span class="text-bright">160zł</span> <span class="italic">(80zł + 80zł nadwyzki)</span><br>
            AGENCJA: <span class="text-bright">40zł</span> <span class="italic">(20zł + 20zł nadwyzki)</span>
          </p>
          <p><button class="tw--btn tw--btn-warning" disabled>Zaksięguj <FontAwesomeIcon icon="not-equal" /></button> - 10zł braku zostaje przeniesione w całości na agencję.</p>
          <p class="ml-5">
            <span class="text-red-700 text-xs">Uwaga! aplikacja oczekuje tylko jednej raty, więc całą prowizja z drugiej raty (nadwyżka) zostanie zaksięgowana na koncie agencji!</span><br>
            AGENT: <span class="text-bright">80zł</span> <span class="italic">(oczekiwana)</span><br>
            AGENCJA: <span class="text-bright">120zł</span> <span class="italic">(20zł + 20zł z drugiej raty + 80zł z części agenta z drugiej raty)</span>
          </p>
        </div>
      </div>

    </template>
  </BaseModal>
</template>

<script>
export default {
  name: 'ProvisionSettlrmrntActionsInfo',
  props: {
    color: {
      type: String,
      default: 'all',
    },
  },
};
</script>