<template>
  <BaseModal
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="false"
    cancelLabel="zamknij"
    :bgClose="true"
    :escCancel="true"
  >
    <template #header>
      Wczytywanie prowizji - info
    </template>
    <template #default>
      <h5 class="text-lg font-semibold">Format pliku prowizji do wczytania</h5>
      Wczytywany plik powinien być w formacie <em>csv rozdzielany przecinkami</em>.<br>
      Oczekiwana struktura pliku do wczytania to 4 kolumny <strong>bez wiersza nagłówkowego</strong>:
      <ol class="list-inside list-decimal">
        <li><strong>Nazwa TU</strong> - informacyjnie, niekoniecznie identyczna jak w aplikacji</li>
        <li><strong>Numer polisy</strong> - wg tej kolumny aplikacja wyszukuje polisy w bazie do przypisania prowizji - <strong><em>pole kluczowe!</em></strong></li>
        <li><strong>Nazwa ubezpieczonego</strong> - pole informacyjne, do pomcy przy poszukiwaniu nieodnalezionych polis. Może być to np. nazwa firmy, skrócona nawa firmy, nazwisko bądź nazwisko i imię ubezpieczonego</li>
        <li><strong>Prowizja</strong> - prowizja wypłacona przez TU - aplikacja podczas księgowania automatycznie dokona podziału na prowizję agenta i pozostałe części. Pole to może być w różnych formatach:</li>
        <ul class="list-inside text-sm">
          <li class="flex gap-x-3 items-center"><FontAwesomeIcon icon="thumbs-up" class="text-green-700" /> 12,34 (przecinek)</li>
          <li class="flex gap-x-3 items-center"><FontAwesomeIcon icon="thumbs-up" class="text-green-700" /> -12,34 (ujemna, przecinek)</li>
          <li class="flex gap-x-3 items-center"><FontAwesomeIcon icon="thumbs-up" class="text-green-700" /> 12.34 (kropka)</li>
          <li class="flex gap-x-3 items-center"><FontAwesomeIcon icon="thumbs-up" class="text-green-700" /> -12.34 (ujemna, kropka)</li>
        </ul>
        Aplikacja wstępnie oczyszcza kolumnę 4 "Prowizja", potrafi usunąć na przykład niedozwolone znaki "zł", "ZŁ" itp, należy jednak w miarę możliwości starać się generować czystą, cyfrową wartość.<br>
        Wartości, które spowodują niepoprawne waczytanie wartości prowizji:
        <ul class="list-inside text-sm">
          <li class="flex gap-x-3 items-center"><FontAwesomeIcon icon="thumbs-down" class="text-red-700" /> 1.234,58 (niedozwolony separator tysięcy)</li>
          <li class="flex gap-x-3 items-center"><FontAwesomeIcon icon="thumbs-down" class="text-red-700" /> 1,234.58 (niedozwolony separator tysięcy)</li>
        </ul>
      </ol>
      <strong>Aplikacja przerywa wczytanie pliku prowizyjnego, jeśli:</strong>
      <ul class="list-inside list-disc">
        <li>pojawi się co najmniej jeden wiersz z pustym numerem polisy</li>
        <li>plik csv będzie miał niepoprawną ilość kolumn</li>
        <li>po wstępnym oczyszczeniu kolumny "prowizja" skrypt nie rozpozna formatu liczbowego</li>
      </ul>
      <span class="font-semibold">Przykład poprawnego pliku prowizyjnego do wczytania:</span>
      <table class="border-collapse border dark:border-gray-600">
        <tbody>
          <tr>
            <td class="px-2 border dark:border-gray-600">Warta Życie</td>
            <td class="px-2 border dark:border-gray-600">99012345678</td>
            <td class="px-2 border dark:border-gray-600">Jan Kowalski</td>
            <td class="px-2 border dark:border-gray-600">26,8</td>
          </tr>
          <tr>
            <td class="px-2 border dark:border-gray-600">PZU</td>
            <td class="px-2 border dark:border-gray-600">1234567890</td>
            <td class="px-2 border dark:border-gray-600">Firma Sp. z o.o.</td>
            <td class="px-2 border dark:border-gray-600">-134</td>
          </tr>
          <tr>
            <td class="px-2 border dark:border-gray-600">Inter Risk</td>
            <td class="px-2 border dark:border-gray-600">KE-D/P0012345</td>
            <td class="px-2 border dark:border-gray-600">Jan Kowalski</td>
            <td class="px-2 border dark:border-gray-600">-123,45</td>
          </tr>
        </tbody>
      </table>
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: 'ProvisionSettlementHelp',
};
</script>
